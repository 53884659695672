
import {Vue, Component, Prop, Emit} from "vue-property-decorator";
import SkillModel from '@/models/SkillModel';

@Component
export default class Dock extends Vue {
    @Prop({default: []}) items: SkillModel[] | undefined;

    key: number = 0;
    selected: number = -1;
    previousBatch: Set<number> = new Set();

    onClick(element: any) {
        this.$emit('click', element);
    }

    hoverCard(index: number) {
        this.selected = index;
    }

    unselect(index: number) {
        if (index == this.selected) {
            this.selected = - 1;
        }
        this.previousBatch.add(index);
    }

    isSelected(cardIndex: number): Boolean {
      return this.selected === cardIndex
    }

    wasSelected(cardIndex: number) {
        let result: Boolean = this.previousBatch.has(cardIndex);

        if (result) {
            this.previousBatch.delete(cardIndex);
        }
        
        return result;
    }

}

