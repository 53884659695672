
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import JourneyButton from '@/components/JourneyButton.vue';
import { bus } from "@/main";

@Component({
    components: {
        JourneyButton
    }
})
export default class ExpPage extends Vue {
    @Prop({ required: true }) title!: string;
    @Prop({ }) titleExtra!: string;
    @Prop({ required: true }) subtitle!: string;
    @Prop({ required: true }) date!: string;

    onExpBtnClicked(element: any) {
        bus.$emit("switchExpPage", element);
    }
}
