
import Vue from 'vue';
import Component from 'vue-class-component';
import ExpPage from '@/components/ExpPage';

@Component ({
    components: {
        ExpPage
    }
})
export default class CegepExpPage extends Vue {
    goToContestArticle() {
        window.open('https://les2rives.com/le-mashup-du-cegep-de-sorel-tracy-met-en-valeur-le-travail-des-etudiants/', '_blank');
    }
}
