import { render, staticRenderFns } from "./SkillDialog.vue?vue&type=template&id=677742dd&scoped=true&"
import script from "./SkillDialog.vue?vue&type=script&lang=ts&"
export * from "./SkillDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "677742dd",
  null
  
)

export default component.exports