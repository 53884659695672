
import Vue, {nextTick} from 'vue';
import Component from 'vue-class-component';
import PersonalCard from '@/components/PersonalCard.vue';
import AnimatedComponent from '@/components/AnimatedComponent.vue';
import ScrolldownIcon from '@/components/ScrolldownIcon.vue';
import LangChooser from '@/components/LangChooser.vue';
import Dock from '@/components/Dock.vue';
import SkillDialog from '@/components/SkillDialog.vue';
import JourneyButton from '@/components/JourneyButton.vue';

import SkillModel from '@/models/SkillModel';
import ExpModel from '@/models/ExpModel';

import CegepExpPage from '@/views/ExpPage/CegepExpPage.vue';
import NexwebExpPage from '@/views/ExpPage/NexwebExpPage.vue';
import TopmenuExpPage from '@/views/ExpPage/TopmenuExpPage.vue';
import EMFluidExpPage from '@/views/ExpPage/EMFluidExpPage.vue';

import { bus } from "@/main";

import {v4 as uuid} from 'uuid';
import {LMap, LTileLayer, LControlZoom, LIcon, LControl} from 'vue2-leaflet';

require("@/scroll.js");

const DEFAULT_Z = 999;
const MAIN_Z = 1000;
const DEFAULT_WIN_WIDTH = 500;
const DEFAULT_WIN_HEIGHT = 300;

@Component({
    components: {
        PersonalCard,
        AnimatedComponent,
        ScrolldownIcon,
        LangChooser,
        Dock,
        LMap,
        LTileLayer,
        LControlZoom,
        LIcon,
        LControl,
        JourneyButton,
        CegepExpPage,
        NexwebExpPage,
        TopmenuExpPage,
        EMFluidExpPage,
        SkillDialog
    }
})
export default class Home extends Vue {
    mapCenter = [45.505331312, -73.55249779];
    mapZoom = 8;
    mapUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
    mapAttribution = "&copy; <a href='https://openstreetmap.org/copyright'>OpenStreetMap contributors</a>";
    selectedBottom = "recent";
    navDirection = "bottom-nav";
    iconSize: number = 64;
    inPage = true;
    showHeadPage = false;
    showExpPage = false;
    showScrollDownText = false;
    expType = "";
    skillDialog = false;
    selectedSkill: SkillModel;
    bubbleTransition = "";
    manualIntroScrollDebounce = false;

    contactMessageSentDialog = false;
    contactFormValid = true;
    contactName = "";
    contactNameRules = [
        (v: any) => !!v || this.$t('contactNameRuleRequired'),
        (v: any) => (v && v.length >= 3) || this.$t('contactNameRuleMin'),
    ]

    contactEmail = "";
    contactEmailRules = [
        (v: any) => !!v || this.$t('contactEmailRuleRequired'),
        (v: any) => /.+@.+\..+/.test(v) || this.$t('contactEmailRuleValid'),
    ]

    contactReason = "";
    contactReasons = [
      { text: this.$t('contactReasonJob'), value: 'contactReasonJob'},
      { text: this.$t('contactReasonContract'), value: 'contactReasonContract'},
      { text: this.$t('contactReasonMentor'), value: 'contactReasonMentor'},
      { text: this.$t('contactReasonOther'), value: 'contactOther'}
    ]
    contactMessage = ""
    contactMessageRules = [
        (v: any) => !!v || this.$t('contactMessageRuleRequired'),
        (v: any) => (v && v.length >= 10) || this.$t('contactMessageRuleMin'),
    ];

    $refs!: {
      nav: HTMLElement,
      sectionContact: HTMLElement,
      map: any,
      skillDialog: SkillDialog,
      contactForm: any
    };

    inMove = false;
    activeSection = 0;
    offsets: any[] = [];
    touchStartY = 0;

    sleep(ms: number): Promise<any> {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    async created() {
      ((that: any) => {
        window.onscroll = function () {
          // navbar function
              if (window.pageYOffset > (500 - that.$refs.nav.offsetHeight)) {
                  that.navDirection = "top-nav" ;
              } else {
                  that.navDirection = "bottom-nav";
              }
        }
      })(this);

      window.addEventListener("DOMContentLoaded", (event) => {
        this.calculateSectionOffsets();
      });
    
      window.addEventListener('DOMMouseScroll', this.handleMouseWheelDOM);  // Mozilla Firefox
      window.addEventListener('mousewheel', this.handleMouseWheel, { passive: false }); // Other browsers
    
      window.addEventListener('touchstart', this.touchStart, { passive: false }); // mobile devices
      window.addEventListener('touchmove', this.touchMove, { passive: false }); // mobile devices
    
      await this.sleep(500);
      this.showHeadPage = true;

      await this.sleep(3500);
      this.showScrollDownText = true;
    }

    manualScrollDownIntro() {
      this.$nextTick(() => {
        if (!this.manualIntroScrollDebounce) {
          this.manualIntroScrollDebounce = true;
        }
        this.activeSection++;
        this.scrollToSection(this.activeSection, true);

        setTimeout(() => {
          this.manualIntroScrollDebounce = false;
        }, 1500);
      })
    }

    mounted() {
      this.onExpBtnClicked(this.$store.state.ExpModule.items[0]);
      this.bubbleTransition = "";

      this.$nextTick(() => {
        this.activeSection = 0;
        this.scrollToSection(this.activeSection, true);
      }, 500);
      

      bus.$on("switchExpPage", (element: any): any => {
        nextTick(() => {
          this.onExpBtnClicked(element);
        });
      });
    }

    onBubbleTransitionFinished() {
        this.$nextTick(() => {
          setTimeout(() => {
              this.bubbleTransition = "wavey";
          }, 500);
        })
    }

    goToContact() {
      this.inPage = false;
      (this.$refs.sectionContact as any).scrollIntoViewPromise({ behavior: "smooth" }).then(() => {
        this.activeSection = 2;
        this.inPage = true;
      })
    }

    goToLinkedin() {
      window.open('https://www.linkedin.com/in/kevin-larocque-a6658b130/', '_blank');
    }

    goToResume() {
      window.open('https://docs.google.com/document/d/1NWw1jlBe_j6IlpbL1mFjFpdQkcR_6v6V/edit?usp=sharing&ouid=115149250920451854386&rtpof=true&sd=true', '_blank');
    }

    mapitemSize(): number[] {
      return [this.iconSize, this.iconSize * 1.15];
    }

    mapitemAnchor(): number[] {
      return [this.iconSize / 2, this.iconSize * 1.15];
    }

    onSkillClicked(skill: SkillModel) {
      this.selectedSkill = skill;
      (this.$refs.skillDialog as SkillDialog).open(skill);
    }

    async onExpBtnClicked(item: ExpModel) {
      let map: any = this.$refs.map.mapObject;
      let zoom: number = map.getZoom();
      let that = this;
      this.expType = item.code;

      if (this.showExpPage) {
        this.showExpPage = false;
        await this.sleep(1000); 
      } 

      map.flyTo(item.position, 12);

      map.once('moveend', function() {
        that.showExpPage = true;
      });
      
    }

    calculateSectionOffsets() {
      let sections = document.getElementsByTagName('section');
      let length = sections.length;

      for(let i = 0; i < length; i++) {
        let sectionOffset = sections[i].offsetTop;
        this.offsets.push(sectionOffset);
      }
    }

    handleMouseWheel(e : any) {
      
      if (e.wheelDelta < 30 && !this.inMove) {
        this.moveUp();
      } else if (e.wheelDelta > 30 && !this.inMove) {
        this.moveDown();
      }
        
      e.preventDefault();
      return false;
    }

    handleMouseWheelDOM (e: any) {
      
      if (e.detail > 0 && !this.inMove) {
        this.moveUp();
      } else if (e.detail < 0 && !this.inMove) {
        this.moveDown();
      }
      
      return false;
    }

    moveDown() {
      this.inMove = true;
      this.activeSection--;
        
      if(this.activeSection < 0) this.activeSection = 0;
        
      this.scrollToSection(this.activeSection, true);
    }

    moveUp() {
      this.inMove = true;
      this.activeSection++;
        
      if(this.activeSection > this.offsets.length - 1) this.activeSection--;
        
      this.scrollToSection(this.activeSection, true);
    }

    scrollToSection(id: any, force = false) {
      if(this.inMove && !force) return false;
      
      this.activeSection = id;
      this.inMove = true;
      
      document.getElementsByTagName('section')[id].scrollIntoView({behavior: 'smooth'});
      
      setTimeout(() => {
        this.inMove = false;
      }, 400);
      
    }

    touchStart(e: any) {
      e.preventDefault();
      
      this.touchStartY = e.touches[0].clientY;
    }

    touchMove(e: any) {
      if(this.inMove) return false;
      e.preventDefault();
      
      const currentY = e.touches[0].clientY;
      
      if(this.touchStartY < currentY) {
        this.moveDown();
      } else {
        this.moveUp();
      }
      
      this.touchStartY = 0;
      return false;
    }

    sendContactMessage() {
      if (this.$refs.contactForm.validate()) {
        let contactObject: any = {
          name: this.contactName,
          email: this.contactEmail,
          reason: this.contactReason,
          message: this.contactMessage
        };

        this.contactName = "";
        this.contactEmail = "";
        this.contactMessage = "";
        this.contactReason = "";

        this.$store.dispatch('ContactModule/sendDiscordNotification', contactObject).then(() => {
          this.contactMessageSentDialog = true;
        })
      }
    }
}
