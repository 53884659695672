
import Vue from 'vue';
import Component from 'vue-class-component';
import ExpPage from '@/components/ExpPage';


@Component ({
    components: {
        ExpPage
    }
})
export default class EMFluidExpPage extends Vue {
}
