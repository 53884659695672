
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class AdminLogin extends Vue {
    showPw = false;
    username = "";
    password = "";
    error = false;

    beforeMount() {
        this.showPw = false;
        this.username = "";
        this.password = "";
        this.error = false;
    }

    validate() {
        this.$store.dispatch('LoginModule/login', {
            username: this.username,
            password: this.password
        }).then((response) => {
            if (response) {
                this.error = false;
                this.$router.push('/admin');
            } else {
                this.error = true;
            }
        })
    }
}
