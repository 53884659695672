import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mt-0 h-100 w-100",attrs:{"max-width":"250","tile":"","shaped":""}},[_c(VImg,{attrs:{"height":"70%","src":require("../assets/smole.png")}}),_c(VCardTitle,{staticClass:"text-center text-no-flex"},[_vm._v(" Kevin Larocque ")]),_c(VCardSubtitle,{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t('workTitle'))+" ")]),_c(VCardActions,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-server")])],1)]}}])},[_c('span',[_vm._v("Devops")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-application-brackets")])],1)]}}])},[_c('span',[_vm._v("Backend")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-cellphone")])],1)]}}])},[_c('span',[_vm._v("Mobile")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }