
import Vue from 'vue';
import Component from 'vue-class-component';
import ExpPage from '@/components/ExpPage';
import PageViewer from "@/components/PageViewer";

@Component ({
    components: {
        ExpPage,
        PageViewer
    }
})
export default class NexwebExpPage extends Vue {
    goToConnectBillingApp() {
        window.open("https://connectbilling.com");
    }
}
