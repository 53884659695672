import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRating } from 'vuetify/lib/components/VRating';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{staticStyle:{"z-index":"99999"},attrs:{"transition":"dialog-bottom-transition","max-width":"600"},model:{value:(_vm.toggleStat),callback:function ($$v) {_vm.toggleStat=$$v},expression:"toggleStat"}},[(_vm.toggleStat)?_c('div',[_c(VCard,[_c(VToolbar,{attrs:{"color":"secondary","dark":""}},[_vm._v(_vm._s(_vm.skill.name))]),_c('v-card-body',{staticClass:"p-3"},[_c(VContainer,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"4"}},[_c(VImg,{attrs:{"max-height":"131","max-width":"275","src":_vm.skill.img}}),_c(VCardTitle,[_vm._v(_vm._s(_vm.$t('skillDescription')))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("skill"+_vm.skill.name+"Description"))+" ")]),_c(VCardTitle,[_vm._v(_vm._s(_vm.$t('skillOverall')))]),_c(VRating,{attrs:{"length":"5","size":"24","value":_vm.skill.rating}})],1),_c(VCol,{attrs:{"cols":"8"}},[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t('skillRating')))]),_c('div',{staticClass:"p-5"},_vm._l((_vm.skill.subskills),function(subskill){return _c('div',[_c('p',[_vm._v(" "+_vm._s(subskill.name)+" ")]),_c(VRating,{attrs:{"length":"5","size":"12","value":subskill.rating}}),_c('br')],1)}),0)],1)],1)],1)],1),_c(VCardActions,{staticClass:"justify-end"},[_c(VBtn,{attrs:{"text":""},on:{"click":_vm.onSkillClose}},[_vm._v(_vm._s(_vm.$t('closeBtn')))])],1)],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }