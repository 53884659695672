
import {Vue, Component, Prop} from "vue-property-decorator";
import ExpModel from "@/models/ExpModel";

@Component
export default class JourneyButton extends Vue {
    @Prop() item: ExpModel | undefined;
    @Prop() index: number | undefined;

    clicked() {
        this.$emit('click', this.item);
    }
}
