
import {Vue, Component, Prop, Watch} from "vue-property-decorator";

const nextAnimation = "slidepreviousviewer";
const previousAnimation = "slidenextviewer";
const nextAnimationAfter = "slideright";
const previousAnimationAfter = "slideleft"; 
@Component
export default class PageViewer extends Vue {
    @Prop({default: 1}) totalPages!: number;

    nextAnimation = nextAnimation;
    previousAnimation = previousAnimation;
    nextAnimationAfter = nextAnimationAfter;
    previousAnimationAfter = previousAnimationAfter;

    currentPage = 1;
    direction = nextAnimation;
    targetDirection = false;
    iteration = 0;
    show = true;

    mounted() {
        this.currentPage = 1;
        this.direction = nextAnimation;
        this.targetDirection = false;
        this.iteration = 0;
        this.show = true;
    }

    previousPage() {
            if (this.currentPage > 1 && !this.targetDirection) {
                this.direction = previousAnimation;
                this.targetDirection = true;
                this.iteration++;
            }
    }

    nextPage() { 
            if (this.currentPage < this.totalPages && !this.targetDirection) {
                this.direction = nextAnimation;
                this.targetDirection = true;
                this.iteration++;
            }
    }

    finishTransition() {
        if (this.direction == nextAnimation && this.targetDirection) {
            
            this.direction = nextAnimationAfter;
            this.show = false;
            this.currentPage++;

            this.$nextTick(() => {
                setTimeout(() => {
                    this.targetDirection = false;
                }, 1000);
            });
        } else if (this.direction == previousAnimation && this.targetDirection) {
            this.show = false;
            this.direction = previousAnimationAfter;
            this.currentPage--;

            this.$nextTick(() => {
                setTimeout(() => {
                    this.targetDirection = false;
                }, 1000);
            });
        }
    }

}

