
import Vue from 'vue';
import Component from 'vue-class-component';
import ExpPage from '@/components/ExpPage';

@Component ({
    components: {
        ExpPage
    }
})
export default class TopmenuExpPage extends Vue {

    goToTopmenuApp() {
        window.open("https://apps.apple.com/ca/app/top-menu/id1471280740");
    }
}
