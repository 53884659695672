
import {Vue, Component, Prop, Emit} from "vue-property-decorator";
import SkillModel from '@/models/SkillModel';

@Component
export default class SkillDialog extends Vue {
    skill: SkillModel;
    toggleStat: Boolean = false;

    onSkillClose() {
      this.toggleStat = false;
    }

    open(skill: SkillModel) {
        this.toggleStat = true;
        this.skill = skill;
    }
}
